// Update all color references in components from [#FF4B2B] to primary
// and accent colors from [#FF6B3B], etc. to secondary

import Hero from "./ai_components/Hero";
import CourseOverview from "./ai_components/CourseOverview";
import WhyUs from "./ai_components/WhyUs";
import Projects from "./ai_components/Projects";
import Learning from "./ai_components/Learning";
import Curriculum from "./ai_components/Curriculum";
import Testimonials from "./ai_components/Testimonials";
import Pricing from "./ai_components/Pricing";
import FAQ from "./ai_components/FAQ";
import CTA from "./ai_components/CTA";
import Footer from "./ai_components/Footer";

function AI_Educate() {
  return (
    <div className="min-h-screen">
      <Hero />
      <CourseOverview />
      <WhyUs />
      <Projects />
      <Learning />
      <Curriculum />
      <Testimonials />
      <Pricing />
      <FAQ />
      <CTA />
      <Footer />
    </div>
  );
}

export default AI_Educate;
