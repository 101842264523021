import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ChakraProvider, IconButton } from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Footer from "./components/Footer";
import FinancialAccounting from "./components/FinancialAccounting";
import OrderManagement from "./components/OrderManagement";
import Manufacturing from "./components/Manufacturing";
import CRM from "./components/CRM";
import Projects from "./components/Projects";
import Helpdesk from "./components/Helpdesk";
import AssetManagement from "./components/AssetManagement";
import Pricing from "./components/Pricing";
import HRPayroll from "./components/HRPayroll";
import ScrollToTop from "./ScrollToTop";
import ComingSoon from "./components/ComingSoon";
import AI_Educate from "./AI_Educate";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AppContent() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {/* <ScrollToTop /> */}
      {location.pathname !== "/aieducate" && <ScrollToTop />}
      {/* Hide Header on /aieducate */}
      {location.pathname !== "/aieducate" && <Header />}

      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/aieducate" element={<AI_Educate />} />
        <Route path="/erp/pricing" element={<Pricing />} />
        <Route path="/erp" element={<HeroSection />} />
        <Route
          path="/erp/financial-accounting"
          element={<FinancialAccounting />}
        />
        <Route path="/erp/order-management" element={<OrderManagement />} />
        <Route path="/erp/hr-and-payroll" element={<HRPayroll />} />
        <Route path="/erp/manufacturing" element={<Manufacturing />} />
        <Route path="/erp/crm" element={<CRM />} />
        <Route path="/erp/projects" element={<Projects />} />
        <Route path="/erp/helpdesk" element={<Helpdesk />} />
        <Route path="/erp/asset-management" element={<AssetManagement />} />
      </Routes>

      <Footer />

      {showScrollButton && (
        <IconButton
          icon={<ChevronUpIcon />}
          position="fixed"
          top="20px"
          right="20px"
          onClick={scrollToTop}
          bgGradient="linear(240deg, #FFDEE9 30%, #B5FFFC 100%)"
          color="black"
          aria-label="Scroll to top"
          _hover={{
            bgGradient: "linear(240deg, #B5FFFC 30%, #FFDEE9 100%)",
            color: "black",
          }}
          isRound
          size="lg"
        />
      )}
    </>
  );
}

function App() {
  return (
    <ChakraProvider>
      <Router>
        <AppContent />
      </Router>
    </ChakraProvider>
  );
}

export default App;
