import { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import ISRO from "../assets/ISRO.png";
import Apple from "../assets/apple.jpeg";
import oglivy from "../assets/oglivy.png";
import tata from "../assets/tata.png";
import google from "../assets/google.png";
import microsoft from "../assets/microsoft.png";
import mygate from "../assets/mygate.png";
import emailjs from "@emailjs/browser";

export default function Hero() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    problemStatement: "",
  });
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.phone || !formData.email) {
      setError("Please fill in all mandatory fields.");
      return;
    }
    setError("");

    try {
      await emailjs.send(
        "service_2c8g9fc", // Replace with your EmailJS Service ID
        "template_3jeaiie", // Replace with your EmailJS Template ID
        {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          problemStatement: formData.problemStatement || "N/A",
        },
        "IuDVZnRbvQc60xVRo" // Replace with your EmailJS Public Key
      );

      // setMessage("Form submitted successfully!");
      setFormData({ name: "", phone: "", email: "", problemStatement: "" });
    } catch (error) {
      console.error("Error:", error);
      // setMessage("Failed to send email.");
    }
    setSubmitted(true);
  };
  const [isFormOpen, setIsFormOpen] = useState(false);
  useEffect(() => {
    if (isFormOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isFormOpen]);
  const companyLogos = [
    { name: "ISRO", logo: ISRO },
    { name: "Ogilvy", logo: oglivy },
    { name: "Apple", logo: Apple },
    { name: "Tata", logo: tata },
    { name: "Google", logo: google },
    { name: "Microsoft", logo: microsoft },
    { name: "myGate", logo: mygate },
  ];

  return (
    <div className="relative min-h-screen bg-white overflow-hidden">
      {/* Background waves */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-r from-[#F5F9FC] to-white opacity-50"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-6xl mx-auto px-4 pt-32 pb-20 text-center">
        <h1 className="text-6xl font-bold mb-6">
          <span className="block text-gray-900">Become the</span>
          <span className="block text-primary">
            AI Expert Everyone Wants to Hire
          </span>
        </h1>

        <p className="text-xl font-mono mb-4">
          Master Generative AI, Smart Automation, Decisioning Systems, and more
          in just 6 months.
        </p>

        <p className="text-gray-600 italic mb-8">
          Next Cohort begins <span className="font-semibold">in February</span>
        </p>

        {/* Waitlist Button */}
        <div className="flex justify-center">
          <button
            onClick={() => setIsFormOpen(true)}
            className="bg-primary text-white px-12 py-4 rounded-lg font-semibold text-lg flex items-center gap-2 hover:bg-primary-hover transition-colors"
          >
            Join the Waitlist <FaArrowRight />
          </button>
        </div>

        {/* Logo Carousel */}
        <div className="mt-16">
          <p className="text-gray-600 font-mono mb-8">
            Students work on projects from companies like
          </p>
          <div className="relative overflow-hidden">
            <div className="flex space-x-16 animate-scroll-infinite">
              {[...companyLogos, ...companyLogos].map((company, index) => (
                <div key={index} className="flex-none">
                  <img
                    src={company.logo}
                    alt={company.name}
                    className="h-8 w-auto opacity-50"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Waitlist Form Popup */}
      {isFormOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="container max-w-3xl mx-auto px-4">
            {/* <div className="text-center mb-8">
              <h2 className="text-3xl font-bold">Inquiry Form</h2>
              <p className="text-gray-600">
                Fill in your details and we will get in touch!
              </p>
            </div> */}

            {submitted ? (
              <>
                <div className="flex flex-col items-center justify-center p-6 bg-green-50 rounded-lg shadow-md animate-fade-in">
                  <div className="text-4xl text-green-600 mb-4">✅</div>
                  <p className="text-2xl font-semibold text-green-700 text-center">
                    Thank you! 🎉 Your inquiry has been sent.
                  </p>
                  <p className="text-gray-600 mt-2 text-center">
                    We will get back to you soon. Stay tuned!
                  </p>
                  <button
                    onClick={() => {
                      setIsFormOpen(false);
                      setSubmitted(false);
                    }}
                    className="mt-6 bg-green-600 text-white py-2 px-6 rounded-lg text-lg font-semibold hover:bg-green-700 transition"
                  >
                    Close
                  </button>
                </div>
              </>
            ) : (
              <form
                className="bg-white p-6 rounded-lg shadow-md"
                onSubmit={handleSubmit}
              >
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                <div className="relative mb-10">
                  {/* Close Button */}
                  <button
                    onClick={() => setIsFormOpen(false)}
                    className="absolute top-1 right-2 text-gray-600 hover:text-gray-900"
                  >
                    ✕
                  </button>
                </div>
                <h2 className="text-xl font-bold mb-4 text-center">
                  Join the Waitlist
                </h2>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">
                    Name *
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full p-3 border rounded-lg"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">
                    Phone *
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    className="w-full p-3 border rounded-lg"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">
                    Email *
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="w-full p-3 border rounded-lg"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">
                    Any specific problem statement to solve? (Optional)
                  </label>
                  <textarea
                    name="problemStatement"
                    className="w-full p-3 border rounded-lg"
                    onChange={handleChange}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="w-full bg-primary text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition"
                >
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
